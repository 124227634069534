/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const SINGULARS =
{
  "casosPartidas": "casosPartida",
  "casosPartida": "casosPartida",
  "casosPedimientos": "casosPedimiento",
  "casosPedimiento": "casosPedimiento",
  "clients": "client",
  "client": "client",
  "contenedoreses": "contenedores",
  "contenedores": "contenedores",
  "contribucionesDeLaPartidas": "contribucionesDeLaPartida",
  "contribucionesDeLaPartida": "contribucionesDeLaPartida",
  "contribucionesPedimientos": "contribucionesPedimiento",
  "contribucionesPedimiento": "contribucionesPedimiento",
  "cuentasAduanerases": "cuentasAduaneras",
  "cuentasAduaneras": "cuentasAduaneras",
  "cuentasAduanerasGarantiaes": "cuentasAduanerasGarantia",
  "cuentasAduanerasGarantia": "cuentasAduanerasGarantia",
  "datosGeneraleses": "datosGenerales",
  "datosGenerales": "datosGenerales",
  "descargosDeMercanciaes": "descargosDeMercancia",
  "descargosDeMercancia": "descargosDeMercancia",
  "destinatariosDeLaMercanciaes": "destinatariosDeLaMercancia",
  "destinatariosDeLaMercancia": "destinatariosDeLaMercancia",
  "diferenciasContribucionesPedimientos": "diferenciasContribucionesPedimiento",
  "diferenciasContribucionesPedimiento": "diferenciasContribucionesPedimiento",
  "facturases": "facturas",
  "facturas": "facturas",
  "fechaPedimientos": "fechaPedimiento",
  "fechaPedimiento": "fechaPedimiento",
  "guiases": "guias",
  "guias": "guias",
  "incidenciasDelReconocimientoAduaneros": "incidenciasDelReconocimientoAduanero",
  "incidenciasDelReconocimientoAduanero": "incidenciasDelReconocimientoAduanero",
  "mercanciases": "mercancias",
  "mercancias": "mercancias",
  "observacionesDeLaPartidas": "observacionesDeLaPartida",
  "observacionesDeLaPartida": "observacionesDeLaPartida",
  "observacionesPedimientos": "observacionesPedimiento",
  "observacionesPedimiento": "observacionesPedimiento",
  "partidases": "partidas",
  "partidas": "partidas",
  "permisosPartidas": "permisosPartida",
  "permisosPartida": "permisosPartida",
  "processes": "process",
  "process": "process",
  "rectificacioneses": "rectificaciones",
  "rectificaciones": "rectificaciones",
  "seleccionAutomatizadas": "seleccionAutomatizada",
  "seleccionAutomatizada": "seleccionAutomatizada",
  "tablases": "tablas",
  "tablas": "tablas",
  "tasasContribucionesPartidas": "tasasContribucionesPartida",
  "tasasContribucionesPartida": "tasasContribucionesPartida",
  "tasasPedimientos": "tasasPedimiento",
  "tasasPedimiento": "tasasPedimiento",
  "teams": "team",
  "team": "team",
  "transporteMercanciases": "transporteMercancias",
  "transporteMercancias": "transporteMercancias",
  "users": "user",
  "user": "user",
  "zips": "zip",
  "zip": "zip",
};