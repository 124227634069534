import React from 'react';
import cx from 'classnames';
import { useQuery, useDelete } from 'seed/gql'
import { DELETE_PROCESS } from 'seed/gql/queries'
import { Link, Route } from 'react-router-dom';
import Export from 'components/processes/Export'
import { Modal } from 'seed/helpers'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/processes/List.module.css';

function Parser(props) {
  const url = props.match.url;
  const path = props.match.path;
  const userId = sessionStorage.getItem("id")
  const isAdmin = sessionStorage.getItem("ADMIN")

  const qProcesses = useQuery(`{
    processes {
      id
      type
      date
      status
      description
      result
    }
  
  }`, !isAdmin ?  `team.users = ${userId}` : "", { orderBy: "-date" })

  const [cDelete, qDelete] = useDelete(DELETE_PROCESS, {
    onCompleted: () => window.location.reload()
  });

  if (qProcesses.isLoading || qDelete.isLoading) return <Loading />
  if (qProcesses.error || qDelete.error) return "Error"

  const getType = (data) => {
    const type = data.type;
    if (type == "UPLOAD") return "CARGA DE DATOS";
    if (type == "QUERY") return "GENERAR EXCEL";
    return ""
  }

  const getTime = (data) => {
    const date = new Date(data.date)
    return date.getTime();
  }

  const getDate = (data) => {
    const date = new Date(data.date)
    const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    return date.getDate() + "." + months[date.getMonth()] + "." + (date.getFullYear())
  }

  const getStatus = (data) => {
    if (data.status == "COMPLETE") return "Completado"
    if (data.status == "ERROR") return "Error"
    if (data.status == "IN_PROCESS") return "Procesando..."
    return ""
  }

  const getDescription = (data) => {
    return data.description.length > 60 ?
      data.description.substring(0, 60) : data.description;
  }

  const getActions = (data) => {
    const type = getType(data);
    const status = getStatus(data);
    const result = (data.result)
    if (type == "GENERAR EXCEL" && status == "Completado")
      return (
        <div>
          <div className={cx(styles.call, styles.smallCall)}>
            <a href={result.url} style={{ color: "#007473" }}>Descargar</a>
          </div>&nbsp;
          <i className="fas fa-times-circle" onClick={() => cDelete({ id: data.id })}></i>
        </div>
      );
    return <i className="fas fa-times-circle" onClick={() => cDelete({ id: data.id })}></i>
  }

  let { processes = [] } = qProcesses.data;

  const ExportModal = props =>
    <Modal width={500} height={550}{...props} >
      <Export {...props} />
    </Modal>


  return (
    <div className={styles.module}>
      <div className={styles.card}>
        <div className={styles.title}>Adm. de datos

          <Link to={`${url}/upload`} className={styles.call}>
            <i className="fas fa-upload"></i>
            Cargar datos</Link>
          <Link to={`${url}/request`} className={cx(styles.call, styles.excel)}>
            <i className="fas fa-plus"></i>
            Generar excel</Link>
        </div>
        <table>
          <tr>
            <th>Proceso</th>
            <th>Fecha</th>
            <th>Estatus</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
          {
            processes.map(process =>
              <tr>
                <td>{getType(process)}</td>
                <td>{getDate(process)}</td>
                <td>{getStatus(process)}</td>
                <td>{getDescription(process)}</td>
                <td>{getActions(process)}</td>
              </tr>)
          }
        </table>
      </div>

      <Route
        path={`${path}/request`}
        component={ExportModal} />

    </div>
  );
}

export default Parser;
