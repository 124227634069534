import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Topnav from 'components/navigation/Topnav';
import Tabnav from 'components/navigation/Tabnav';
import Processes from 'components/processes/List'
import Upload from 'components/processes/Upload';
import Zips from 'components/admin/Zips';
import Clients from 'components/admin/Clients';
import Dashboard from 'components/dashboard/Dashboard'
import { useGetCall } from 'seed/api';
import { Modal } from 'seed/helpers'
import styles from 'styles/css/Home.module.css';

function Home(props) {
  const { path } = props.match;

  const [isAuth, setIsAuth] = useState(false);
  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => {
      sessionStorage.setItem("ADMIN", data.team_ids.indexOf(11) != -1)
      setIsAuth(true);
    },
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace(`/login?next=${encodeURIComponent(new URL(window.location.href).pathname)}`);
    }
  }) 

  useEffect(() =>
  {
    callAuth();
  }, []);

  if (!isAuth) return null;

  const UploadModal = props =>
    <Modal width={440} height={380}{...props} >
      <Upload {...props} />
    </Modal>

  const ZipsModal = props =>
    <Modal width={470} height={520}{...props} >
      <Zips {...props} />
    </Modal>

  const ClientsModal = props =>
    <Modal width={470} height={520}{...props} >
      <Clients {...props} />
    </Modal>

  const TabNavWrapper = props_i =>
    <Tabnav match={props.match} />

  return (
    <div className={styles.module}>

      <div className={styles.container}>
        <div className={styles.topnav}>
          <Route path={`${path}/:section?`}
            component={Topnav} />
        </div>
        <div className={styles.tabnav}>
          <Route path={`${path}/:section?`}
            component={TabNavWrapper} />
        </div>

        <div className={styles.content}>
          <Switch>
            <Route path={`${path}/admin`}
              component={Processes} />
            <Route path={`${path}/zips`}
              component={ZipsModal} />
            <Route path={`${path}/clients`}
              component={ClientsModal} />
            <Route path={`${path}/reporte`}
              component={Dashboard} />
            <Redirect to={`${path}/admin`} />
          </Switch>
        </div>
        <Route
          path={[`${path}/:section/upload`, `${path}/upload`]}
          component={UploadModal} />
      </div>
    </div>
  );
}

export default Home;
