import React, { useState } from 'react';
import Svg from 'react-svg';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import cx from 'classnames';

import styles from 'styles/css/navigation/Topnav.module.css';

function Topnav(props) {
  let { section = "" } = props.match.params;
  const [state, setState] = useState({});

  const openOptionMenu = e =>
    setState({ optionMenu: e.currentTarget });

  const closeOptionMenu = () =>
    setState({ optionMenu: null });

  const onClickLogout = () => {
    props.history.push("/logout");
    closeOptionMenu();
  }

  const title =
    section.charAt(0).toUpperCase() +
    section.replace(/_/g, " ").slice(1);

  return (
    <div className={styles.module}>

      <div className={styles.navLeft}>
        <div className={styles.dots}></div>
        <div className={styles.logo}>
          <img src="/resources/icons/chevez.png" alt="Chevez Ruiz Zamarripa" />
        </div>
        <div>
          <h5>Visor de Comercio Exterior <span>{title}</span></h5>
        </div>
      </div>

      <div className={cx(styles.expand)}
        onClick={openOptionMenu}>
        Opciones
          <i className={cx("fas fa-caret-down")}></i>
      </div>

      <Menu
        anchorEl={state.optionMenu}
        open={Boolean(state.optionMenu)}
        onClose={closeOptionMenu}>
        <MenuItem onClick={onClickLogout}>Cerrar sesión</MenuItem>
      </Menu>
    </div>
  );
}

export default Topnav;

