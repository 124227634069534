import React from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api'
import { useQuery, useDelete } from 'seed/gql'
import { DELETE_ZIP } from 'seed/gql/queries'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/admin/Zips.module.css';


function Zips(props) {
  const userId = sessionStorage.getItem("id")
  const isAdmin = sessionStorage.getItem("ADMIN")

  const qZips = useQuery(`{
    zips {     
      name
      client{
        name
      }
    }  
  }`, !isAdmin ? `client.team.users = ${userId}` : "", { orderBy: "name" });

  const [cDelete, qDelete] = useDelete(DELETE_ZIP, {
    onCompleted: () => window.location.reload()
  });

  if (qZips.error || qDelete.error) return "Error"
  if (qZips.isLoading || qDelete.isLoading) return <Loading />

  const { zips = [] } = qZips.data

  const deleteZip = (zipId) => {
    const p = window.confirm("¿Estás seguro de eliminar los registros de este datastage?");
    if (p == true)
      cDelete({ id: zipId });
  }

  return (
    <div className={styles.module}>
      <div className={styles.jumbotron}>
        <h4 style={{ color: "#fff" }}>Datastages cargados</h4>
      </div>

      <div className={cx(styles.paper, styles.form)}>

        <table>
          <tr>
            <th>Nombre</th>
            <th>Cliente</th>
            <th></th>
          </tr>
          {
            zips.map(zip =>
              <tr>
                <td>{zip.name}</td>
                <td>{zip.client.name}</td>
                <td>
                  <i className="fas fa-trash" style={{ minWidth: "20px", minHeight: "20px", display: "block" }} onClick={() => deleteZip(zip.id)}></i>
                </td>
              </tr>)
          }
        </table>
      </div>
    </div>
  );
}

export default Zips;
