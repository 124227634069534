import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Home from 'components/Home';
import Login from 'components/auth/Login';
import LoginAuth from 'components/auth/LoginAuth';
import Logout from 'components/auth/Logout';

import styles from 'styles/css/App.module.css';

function App(props) {
  return (
    <div className={styles.module}>
      <Router>
        <Switch>
          <Route path="/login/auth"
            component={LoginAuth} />
          <Route path="/login"
            component={Login} />
          <Route path="/logout"
            component={Logout} />
          <Route path="/app"
            component={Home} />
          <Redirect to="/app" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
