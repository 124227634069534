import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from 'styles/css/dashboard/PrintDialog.module.css';


function PrintDialog()
{  
  let pluginUrl = "https://chrome.google.com/webstore/detail/gofullpage-full-page-scre/fdpohaocaechififmbbbbbknoalclacl"
  return (
    <div className={styles.module}>
      <div className={styles.jumbotron}>
        <h4>Imprimir reporte</h4>
      </div>

      <div className={cx(styles.paper, styles.form)}>
        <div className={styles.instructions}>
          <h5>Instrucciones</h5>
          <ol>
            <li>Abrir el visor desde Google Chrome</li>
            <li>Instalar la app de impresión en la liga: <a href={pluginUrl}>{pluginUrl}</a></li>
            <div className={styles.divider}></div>
            <li>Presionar el botón inferior de &quot;imprimir&quot;</li>
            <li>En la nueva pantalla re-seleccionar los filtros</li>
            <li>Llamar la app de impresión en la esquina superior izquierda</li>
            <img src="/resources/images/captura.png"></img>
          </ol>
        </div>
        <Link target="_blank" to={`/print?id=${sessionStorage.getItem("id")}&token=${sessionStorage.getItem("token")}`} 
        className={styles.printButton}>Imprimir</Link>
      </div>
      </div>
  );
}

export default PrintDialog;
