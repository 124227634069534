/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const CASOS_PARTIDA = `
{
  casosPartida {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    claveCaso
    identificadorCaso
    complementoCaso
    fechaPagoReal
    pns
    pnszs
    zip {
      id
    }
  }
}
`;

export const SET_CASOS_PARTIDA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $claveCaso: String,
  $identificadorCaso: String,
  $complementoCaso: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $zip: Int,
)
{
  setCasosPartida(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveCaso: $claveCaso,
    identificadorCaso: $identificadorCaso,
    complementoCaso: $complementoCaso,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    casosPartida {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      claveCaso
      identificadorCaso
      complementoCaso
      fechaPagoReal
      pns
      pnszs
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_CASOS_PARTIDA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $claveCaso: String!,
  $identificadorCaso: String!,
  $complementoCaso: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $zip: Int!,
)
{
  saveCasosPartida(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveCaso: $claveCaso,
    identificadorCaso: $identificadorCaso,
    complementoCaso: $complementoCaso,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    casosPartida {
      id
    }
  }
}
`;

export const DELETE_CASOS_PARTIDA = `
mutation Delete($id: Int!)
{
  deleteCasosPartida(id: $id)
  {
    id
  }
}
`;

export const CASOS_PEDIMIENTO = `
{
  casosPedimiento {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    caso
    idCaso
    tipoProcedimiento
    complementoCaso
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_CASOS_PEDIMIENTO = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $caso: String,
  $idCaso: String,
  $tipoProcedimiento: Int,
  $complementoCaso: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setCasosPedimiento(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    caso: $caso,
    idCaso: $idCaso,
    tipoProcedimiento: $tipoProcedimiento,
    complementoCaso: $complementoCaso,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    casosPedimiento {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      caso
      idCaso
      tipoProcedimiento
      complementoCaso
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_CASOS_PEDIMIENTO = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $caso: String!,
  $idCaso: String!,
  $tipoProcedimiento: Int!,
  $complementoCaso: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveCasosPedimiento(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    caso: $caso,
    idCaso: $idCaso,
    tipoProcedimiento: $tipoProcedimiento,
    complementoCaso: $complementoCaso,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    casosPedimiento {
      id
    }
  }
}
`;

export const DELETE_CASOS_PEDIMIENTO = `
mutation Delete($id: Int!)
{
  deleteCasosPedimiento(id: $id)
  {
    id
  }
}
`;

export const CLIENT = `
{
  client {
    id
    name
    team {
      id
    }
  }
}
`;

export const SET_CLIENT = `
mutation Set(
  $id: Int!,
  $name: String,
  $team: Int,
)
{
  setClient(
    id: $id,
    name: $name,
    team: $team,
  ) {
    client {
      id
      name
      team {
        id
      }
    }
  }
}
`;

export const SAVE_CLIENT = `
mutation Save(
  $name: String!,
  $team: Int!,
)
{
  saveClient(
    name: $name,
    team: $team,
  ) {
    client {
      id
    }
  }
}
`;

export const DELETE_CLIENT = `
mutation Delete($id: Int!)
{
  deleteClient(id: $id)
  {
    id
  }
}
`;

export const CONTENEDORES = `
{
  contenedores {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    contenedor
    tipoContenedor
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_CONTENEDORES = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $contenedor: String,
  $tipoContenedor: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setContenedores(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    contenedor: $contenedor,
    tipoContenedor: $tipoContenedor,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    contenedores {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      contenedor
      tipoContenedor
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_CONTENEDORES = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $contenedor: String!,
  $tipoContenedor: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveContenedores(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    contenedor: $contenedor,
    tipoContenedor: $tipoContenedor,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    contenedores {
      id
    }
  }
}
`;

export const DELETE_CONTENEDORES = `
mutation Delete($id: Int!)
{
  deleteContenedores(id: $id)
  {
    id
  }
}
`;

export const CONTRIBUCIONES_DE_LA_PARTIDA = `
{
  contribucionesDeLaPartida {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    claveContribucion
    formaPago
    importePago
    fechaPagoReal
    pns
    pnszs
    zip {
      id
    }
  }
}
`;

export const SET_CONTRIBUCIONES_DE_LA_PARTIDA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $claveContribucion: String,
  $formaPago: String,
  $importePago: Float,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $zip: Int,
)
{
  setContribucionesDeLaPartida(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveContribucion: $claveContribucion,
    formaPago: $formaPago,
    importePago: $importePago,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    zip: $zip,
    pnszs: $pnszs,
  ) {
    contribucionesDeLaPartida {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      claveContribucion
      formaPago
      importePago
      fechaPagoReal
      pns
      pnszs
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_CONTRIBUCIONES_DE_LA_PARTIDA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $claveContribucion: String!,
  $formaPago: String!,
  $importePago: Float!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $zip: Int!,
)
{
  saveContribucionesDeLaPartida(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveContribucion: $claveContribucion,
    formaPago: $formaPago,
    importePago: $importePago,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    zip: $zip,
    pnszs: $pnszs,
  ) {
    contribucionesDeLaPartida {
      id
    }
  }
}
`;

export const DELETE_CONTRIBUCIONES_DE_LA_PARTIDA = `
mutation Delete($id: Int!)
{
  deleteContribucionesDeLaPartida(id: $id)
  {
    id
  }
}
`;

export const CONTRIBUCIONES_PEDIMIENTO = `
{
  contribucionesPedimiento {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    contribucion
    formaPago
    importePago
    tipoPedimiento
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_CONTRIBUCIONES_PEDIMIENTO = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $contribucion: String,
  $formaPago: String,
  $importePago: Float,
  $tipoPedimiento: Int,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setContribucionesPedimiento(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    contribucion: $contribucion,
    formaPago: $formaPago,
    importePago: $importePago,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    zip: $zip,
    pnsz: $pnsz,
  ) {
    contribucionesPedimiento {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      contribucion
      formaPago
      importePago
      tipoPedimiento
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_CONTRIBUCIONES_PEDIMIENTO = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $contribucion: String!,
  $formaPago: String!,
  $importePago: Float!,
  $tipoPedimiento: Int!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveContribucionesPedimiento(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    contribucion: $contribucion,
    formaPago: $formaPago,
    importePago: $importePago,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    zip: $zip,
    pnsz: $pnsz,
  ) {
    contribucionesPedimiento {
      id
    }
  }
}
`;

export const DELETE_CONTRIBUCIONES_PEDIMIENTO = `
mutation Delete($id: Int!)
{
  deleteContribucionesPedimiento(id: $id)
  {
    id
  }
}
`;

export const CUENTAS_ADUANERAS = `
{
  cuentasAduaneras {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    institucionEmisora
    numeroCuenta
    folioConstancia
    fechaConstancia
    tipoCuenta
    garantia
    valorUnitarioTitulo
    totalGarantia
    unidadPrecioEstimado
    titulosAsignados
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_CUENTAS_ADUANERAS = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $institucionEmisora: Int,
  $numeroCuenta: Int,
  $folioConstancia: String,
  $fechaConstancia: DateTime,
  $tipoCuenta: Int,
  $garantia: Int,
  $valorUnitarioTitulo: Float,
  $totalGarantia: Float,
  $unidadPrecioEstimado: Float,
  $titulosAsignados: Float,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setCuentasAduaneras(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    institucionEmisora: $institucionEmisora,
    numeroCuenta: $numeroCuenta,
    folioConstancia: $folioConstancia,
    fechaConstancia: $fechaConstancia,
    tipoCuenta: $tipoCuenta,
    garantia: $garantia,
    valorUnitarioTitulo: $valorUnitarioTitulo,
    totalGarantia: $totalGarantia,
    unidadPrecioEstimado: $unidadPrecioEstimado,
    titulosAsignados: $titulosAsignados,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    cuentasAduaneras {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      institucionEmisora
      numeroCuenta
      folioConstancia
      fechaConstancia
      tipoCuenta
      garantia
      valorUnitarioTitulo
      totalGarantia
      unidadPrecioEstimado
      titulosAsignados
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_CUENTAS_ADUANERAS = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $institucionEmisora: Int!,
  $numeroCuenta: Int!,
  $folioConstancia: String!,
  $fechaConstancia: DateTime!,
  $tipoCuenta: Int!,
  $garantia: Int!,
  $valorUnitarioTitulo: Float!,
  $totalGarantia: Float!,
  $unidadPrecioEstimado: Float!,
  $titulosAsignados: Float!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveCuentasAduaneras(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    institucionEmisora: $institucionEmisora,
    numeroCuenta: $numeroCuenta,
    folioConstancia: $folioConstancia,
    fechaConstancia: $fechaConstancia,
    tipoCuenta: $tipoCuenta,
    garantia: $garantia,
    valorUnitarioTitulo: $valorUnitarioTitulo,
    totalGarantia: $totalGarantia,
    unidadPrecioEstimado: $unidadPrecioEstimado,
    titulosAsignados: $titulosAsignados,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    cuentasAduaneras {
      id
    }
  }
}
`;

export const DELETE_CUENTAS_ADUANERAS = `
mutation Delete($id: Int!)
{
  deleteCuentasAduaneras(id: $id)
  {
    id
  }
}
`;

export const CUENTAS_ADUANERAS_GARANTIA = `
{
  cuentasAduanerasGarantia {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    institucionEmisora
    numeroCuenta
    folioConstancia
    fechaConstancia
    claveGarantia
    valorUnitarioTitulo
    totalGarantia
    unidadesMedidalPrecioEstimado
    titulosAsignados
    fechaPagoReal
    pns
    pnszs
    zip {
      id
    }
  }
}
`;

export const SET_CUENTAS_ADUANERAS_GARANTIA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $institucionEmisora: Int,
  $numeroCuenta: Int,
  $folioConstancia: String,
  $fechaConstancia: DateTime,
  $claveGarantia: Int,
  $valorUnitarioTitulo: Float,
  $totalGarantia: Float,
  $unidadesMedidalPrecioEstimado: Float,
  $titulosAsignados: Float,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $zip: Int,
)
{
  setCuentasAduanerasGarantia(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    institucionEmisora: $institucionEmisora,
    numeroCuenta: $numeroCuenta,
    folioConstancia: $folioConstancia,
    fechaConstancia: $fechaConstancia,
    claveGarantia: $claveGarantia,
    valorUnitarioTitulo: $valorUnitarioTitulo,
    totalGarantia: $totalGarantia,
    unidadesMedidalPrecioEstimado: $unidadesMedidalPrecioEstimado,
    titulosAsignados: $titulosAsignados,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    cuentasAduanerasGarantia {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      institucionEmisora
      numeroCuenta
      folioConstancia
      fechaConstancia
      claveGarantia
      valorUnitarioTitulo
      totalGarantia
      unidadesMedidalPrecioEstimado
      titulosAsignados
      fechaPagoReal
      pns
      pnszs
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_CUENTAS_ADUANERAS_GARANTIA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $institucionEmisora: Int!,
  $numeroCuenta: Int!,
  $folioConstancia: String!,
  $fechaConstancia: DateTime!,
  $claveGarantia: Int!,
  $valorUnitarioTitulo: Float!,
  $totalGarantia: Float!,
  $unidadesMedidalPrecioEstimado: Float!,
  $titulosAsignados: Float!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $zip: Int!,
)
{
  saveCuentasAduanerasGarantia(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    institucionEmisora: $institucionEmisora,
    numeroCuenta: $numeroCuenta,
    folioConstancia: $folioConstancia,
    fechaConstancia: $fechaConstancia,
    claveGarantia: $claveGarantia,
    valorUnitarioTitulo: $valorUnitarioTitulo,
    totalGarantia: $totalGarantia,
    unidadesMedidalPrecioEstimado: $unidadesMedidalPrecioEstimado,
    titulosAsignados: $titulosAsignados,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    cuentasAduanerasGarantia {
      id
    }
  }
}
`;

export const DELETE_CUENTAS_ADUANERAS_GARANTIA = `
mutation Delete($id: Int!)
{
  deleteCuentasAduanerasGarantia(id: $id)
  {
    id
  }
}
`;

export const DATOS_GENERALES = `
{
  datosGenerales {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    tipoOperacion
    claveDocumento
    seccionAduaneraEntrada
    curpContribuyente
    rfc
    curpAduanal
    tipoCambio
    totalFletes
    totalSeguros
    totalEmbalajes
    totalIncrementables
    totalDeducibles
    pesoBrutoMercancia
    transporteSalida
    transporteArribo
    transporteEntradaSalida
    destinoMercancia
    nombreContribuyente
    calleContribuyente
    numeroInteriorContribuyente
    numeroExteriorContribuyente
    cpContribuyente
    municipioContribuyente
    entidadFederativaContribuyente
    paisContribuyente
    pedimiento
    fechaPedimiento
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_DATOS_GENERALES = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $tipoOperacion: String,
  $claveDocumento: String,
  $seccionAduaneraEntrada: String,
  $curpContribuyente: String,
  $rfc: String,
  $curpAduanal: String,
  $tipoCambio: Float,
  $totalFletes: Float,
  $totalSeguros: Float,
  $totalEmbalajes: Float,
  $totalIncrementables: Float,
  $totalDeducibles: Float,
  $pesoBrutoMercancia: Float,
  $transporteSalida: String,
  $transporteArribo: String,
  $transporteEntradaSalida: String,
  $destinoMercancia: String,
  $nombreContribuyente: String,
  $calleContribuyente: String,
  $numeroInteriorContribuyente: String,
  $numeroExteriorContribuyente: String,
  $cpContribuyente: String,
  $municipioContribuyente: String,
  $entidadFederativaContribuyente: String,
  $paisContribuyente: String,
  $pedimiento: Int,
  $fechaPedimiento: DateTime,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setDatosGenerales(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    tipoOperacion: $tipoOperacion,
    claveDocumento: $claveDocumento,
    seccionAduaneraEntrada: $seccionAduaneraEntrada,
    curpContribuyente: $curpContribuyente,
    rfc: $rfc,
    curpAduanal: $curpAduanal,
    tipoCambio: $tipoCambio,
    totalFletes: $totalFletes,
    totalSeguros: $totalSeguros,
    totalEmbalajes: $totalEmbalajes,
    totalIncrementables: $totalIncrementables,
    totalDeducibles: $totalDeducibles,
    pesoBrutoMercancia: $pesoBrutoMercancia,
    transporteSalida: $transporteSalida,
    transporteArribo: $transporteArribo,
    transporteEntradaSalida: $transporteEntradaSalida,
    destinoMercancia: $destinoMercancia,
    nombreContribuyente: $nombreContribuyente,
    calleContribuyente: $calleContribuyente,
    numeroInteriorContribuyente: $numeroInteriorContribuyente,
    numeroExteriorContribuyente: $numeroExteriorContribuyente,
    cpContribuyente: $cpContribuyente,
    municipioContribuyente: $municipioContribuyente,
    entidadFederativaContribuyente: $entidadFederativaContribuyente,
    paisContribuyente: $paisContribuyente,
    pedimiento: $pedimiento,
    fechaPedimiento: $fechaPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    datosGenerales {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      tipoOperacion
      claveDocumento
      seccionAduaneraEntrada
      curpContribuyente
      rfc
      curpAduanal
      tipoCambio
      totalFletes
      totalSeguros
      totalEmbalajes
      totalIncrementables
      totalDeducibles
      pesoBrutoMercancia
      transporteSalida
      transporteArribo
      transporteEntradaSalida
      destinoMercancia
      nombreContribuyente
      calleContribuyente
      numeroInteriorContribuyente
      numeroExteriorContribuyente
      cpContribuyente
      municipioContribuyente
      entidadFederativaContribuyente
      paisContribuyente
      pedimiento
      fechaPedimiento
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_DATOS_GENERALES = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $tipoOperacion: String!,
  $claveDocumento: String!,
  $seccionAduaneraEntrada: String!,
  $curpContribuyente: String!,
  $rfc: String!,
  $curpAduanal: String!,
  $tipoCambio: Float!,
  $totalFletes: Float!,
  $totalSeguros: Float!,
  $totalEmbalajes: Float!,
  $totalIncrementables: Float!,
  $totalDeducibles: Float!,
  $pesoBrutoMercancia: Float!,
  $transporteSalida: String!,
  $transporteArribo: String!,
  $transporteEntradaSalida: String!,
  $destinoMercancia: String!,
  $nombreContribuyente: String!,
  $calleContribuyente: String!,
  $numeroInteriorContribuyente: String!,
  $numeroExteriorContribuyente: String!,
  $cpContribuyente: String!,
  $municipioContribuyente: String!,
  $entidadFederativaContribuyente: String!,
  $paisContribuyente: String!,
  $pedimiento: Int!,
  $fechaPedimiento: DateTime!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveDatosGenerales(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    tipoOperacion: $tipoOperacion,
    claveDocumento: $claveDocumento,
    seccionAduaneraEntrada: $seccionAduaneraEntrada,
    curpContribuyente: $curpContribuyente,
    rfc: $rfc,
    curpAduanal: $curpAduanal,
    tipoCambio: $tipoCambio,
    totalFletes: $totalFletes,
    totalSeguros: $totalSeguros,
    totalEmbalajes: $totalEmbalajes,
    totalIncrementables: $totalIncrementables,
    totalDeducibles: $totalDeducibles,
    pesoBrutoMercancia: $pesoBrutoMercancia,
    transporteSalida: $transporteSalida,
    transporteArribo: $transporteArribo,
    transporteEntradaSalida: $transporteEntradaSalida,
    destinoMercancia: $destinoMercancia,
    nombreContribuyente: $nombreContribuyente,
    calleContribuyente: $calleContribuyente,
    numeroInteriorContribuyente: $numeroInteriorContribuyente,
    numeroExteriorContribuyente: $numeroExteriorContribuyente,
    cpContribuyente: $cpContribuyente,
    municipioContribuyente: $municipioContribuyente,
    entidadFederativaContribuyente: $entidadFederativaContribuyente,
    paisContribuyente: $paisContribuyente,
    pedimiento: $pedimiento,
    fechaPedimiento: $fechaPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    datosGenerales {
      id
    }
  }
}
`;

export const DELETE_DATOS_GENERALES = `
mutation Delete($id: Int!)
{
  deleteDatosGenerales(id: $id)
  {
    id
  }
}
`;

export const DESCARGOS_DE_MERCANCIA = `
{
  descargosDeMercancia {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    patenteAduanalOriginal
    numeroPedimientoOriginal
    seccionAduaneraDespachooriginal
    documentoOriginal
    fechaOperacionOriginal
    fraccionArancelariaOriginal
    unidadMedidaOriginal
    cantidadMercaciaDescargada
    tipoPedimiento
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_DESCARGOS_DE_MERCANCIA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $patenteAduanalOriginal: Int,
  $numeroPedimientoOriginal: Int,
  $seccionAduaneraDespachooriginal: String,
  $documentoOriginal: String,
  $fechaOperacionOriginal: DateTime,
  $fraccionArancelariaOriginal: String,
  $unidadMedidaOriginal: String,
  $cantidadMercaciaDescargada: Float,
  $tipoPedimiento: Int,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setDescargosDeMercancia(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    patenteAduanalOriginal: $patenteAduanalOriginal,
    numeroPedimientoOriginal: $numeroPedimientoOriginal,
    seccionAduaneraDespachooriginal: $seccionAduaneraDespachooriginal,
    documentoOriginal: $documentoOriginal,
    fechaOperacionOriginal: $fechaOperacionOriginal,
    fraccionArancelariaOriginal: $fraccionArancelariaOriginal,
    unidadMedidaOriginal: $unidadMedidaOriginal,
    cantidadMercaciaDescargada: $cantidadMercaciaDescargada,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    descargosDeMercancia {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      patenteAduanalOriginal
      numeroPedimientoOriginal
      seccionAduaneraDespachooriginal
      documentoOriginal
      fechaOperacionOriginal
      fraccionArancelariaOriginal
      unidadMedidaOriginal
      cantidadMercaciaDescargada
      tipoPedimiento
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_DESCARGOS_DE_MERCANCIA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $patenteAduanalOriginal: Int!,
  $numeroPedimientoOriginal: Int!,
  $seccionAduaneraDespachooriginal: String!,
  $documentoOriginal: String!,
  $fechaOperacionOriginal: DateTime!,
  $fraccionArancelariaOriginal: String!,
  $unidadMedidaOriginal: String!,
  $cantidadMercaciaDescargada: Float!,
  $tipoPedimiento: Int!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveDescargosDeMercancia(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    patenteAduanalOriginal: $patenteAduanalOriginal,
    numeroPedimientoOriginal: $numeroPedimientoOriginal,
    seccionAduaneraDespachooriginal: $seccionAduaneraDespachooriginal,
    documentoOriginal: $documentoOriginal,
    fechaOperacionOriginal: $fechaOperacionOriginal,
    fraccionArancelariaOriginal: $fraccionArancelariaOriginal,
    unidadMedidaOriginal: $unidadMedidaOriginal,
    cantidadMercaciaDescargada: $cantidadMercaciaDescargada,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    descargosDeMercancia {
      id
    }
  }
}
`;

export const DELETE_DESCARGOS_DE_MERCANCIA = `
mutation Delete($id: Int!)
{
  deleteDescargosDeMercancia(id: $id)
  {
    id
  }
}
`;

export const DESTINATARIOS_DE_LA_MERCANCIA = `
{
  destinatariosDeLaMercancia {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    idDestinatarioFiscal
    destinatarioMercancia
    calleDestinatario
    numeroInteriorDestinatario
    numeroExteriorDestinatario
    cpDestinatario
    municipioDestinatario
    paisDestinatario
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_DESTINATARIOS_DE_LA_MERCANCIA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $idDestinatarioFiscal: String,
  $destinatarioMercancia: String,
  $calleDestinatario: String,
  $numeroInteriorDestinatario: String,
  $numeroExteriorDestinatario: String,
  $cpDestinatario: String,
  $municipioDestinatario: String,
  $paisDestinatario: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setDestinatariosDeLaMercancia(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    idDestinatarioFiscal: $idDestinatarioFiscal,
    destinatarioMercancia: $destinatarioMercancia,
    calleDestinatario: $calleDestinatario,
    numeroInteriorDestinatario: $numeroInteriorDestinatario,
    numeroExteriorDestinatario: $numeroExteriorDestinatario,
    cpDestinatario: $cpDestinatario,
    municipioDestinatario: $municipioDestinatario,
    paisDestinatario: $paisDestinatario,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    destinatariosDeLaMercancia {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      idDestinatarioFiscal
      destinatarioMercancia
      calleDestinatario
      numeroInteriorDestinatario
      numeroExteriorDestinatario
      cpDestinatario
      municipioDestinatario
      paisDestinatario
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_DESTINATARIOS_DE_LA_MERCANCIA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $idDestinatarioFiscal: String!,
  $destinatarioMercancia: String!,
  $calleDestinatario: String!,
  $numeroInteriorDestinatario: String!,
  $numeroExteriorDestinatario: String!,
  $cpDestinatario: String!,
  $municipioDestinatario: String!,
  $paisDestinatario: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveDestinatariosDeLaMercancia(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    idDestinatarioFiscal: $idDestinatarioFiscal,
    destinatarioMercancia: $destinatarioMercancia,
    calleDestinatario: $calleDestinatario,
    numeroInteriorDestinatario: $numeroInteriorDestinatario,
    numeroExteriorDestinatario: $numeroExteriorDestinatario,
    cpDestinatario: $cpDestinatario,
    municipioDestinatario: $municipioDestinatario,
    paisDestinatario: $paisDestinatario,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    destinatariosDeLaMercancia {
      id
    }
  }
}
`;

export const DELETE_DESTINATARIOS_DE_LA_MERCANCIA = `
mutation Delete($id: Int!)
{
  deleteDestinatariosDeLaMercancia(id: $id)
  {
    id
  }
}
`;

export const DIFERENCIAS_CONTRIBUCIONES_PEDIMIENTO = `
{
  diferenciasContribucionesPedimiento {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    claveContribucion
    claveFormaPago
    importePago
    tipoPedimiento
    fechaPagoReal
    pns
    pnszs
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_DIFERENCIAS_CONTRIBUCIONES_PEDIMIENTO = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $claveContribucion: Int,
  $claveFormaPago: String,
  $importePago: Int,
  $tipoPedimiento: Int,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $pnsz: String,
  $zip: Int,
)
{
  setDiferenciasContribucionesPedimiento(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    claveContribucion: $claveContribucion,
    claveFormaPago: $claveFormaPago,
    importePago: $importePago,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    diferenciasContribucionesPedimiento {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      claveContribucion
      claveFormaPago
      importePago
      tipoPedimiento
      fechaPagoReal
      pns
      pnszs
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_DIFERENCIAS_CONTRIBUCIONES_PEDIMIENTO = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $claveContribucion: Int!,
  $claveFormaPago: String!,
  $importePago: Int!,
  $tipoPedimiento: Int!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveDiferenciasContribucionesPedimiento(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    claveContribucion: $claveContribucion,
    claveFormaPago: $claveFormaPago,
    importePago: $importePago,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    diferenciasContribucionesPedimiento {
      id
    }
  }
}
`;

export const DELETE_DIFERENCIAS_CONTRIBUCIONES_PEDIMIENTO = `
mutation Delete($id: Int!)
{
  deleteDiferenciasContribucionesPedimiento(id: $id)
  {
    id
  }
}
`;

export const FACTURAS = `
{
  facturas {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fechaFacturacion
    numeroFactura
    terminoFacturacion
    monedaFacturacion
    valorDolares
    monedaExtranjera
    paisFacturacion
    entidadFederativa
    proveedorFiscal
    proveedorMercancia
    calleProveedor
    numeroInteriorProveedor
    numeroExteriorProveedor
    cpProveedor
    municipioProveedor
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_FACTURAS = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fechaFacturacion: DateTime,
  $numeroFactura: String,
  $terminoFacturacion: String,
  $monedaFacturacion: String,
  $valorDolares: Float,
  $monedaExtranjera: Float,
  $paisFacturacion: String,
  $entidadFederativa: String,
  $proveedorFiscal: String,
  $proveedorMercancia: String,
  $calleProveedor: String,
  $numeroInteriorProveedor: String,
  $numeroExteriorProveedor: String,
  $cpProveedor: String,
  $municipioProveedor: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setFacturas(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fechaFacturacion: $fechaFacturacion,
    numeroFactura: $numeroFactura,
    terminoFacturacion: $terminoFacturacion,
    monedaFacturacion: $monedaFacturacion,
    valorDolares: $valorDolares,
    monedaExtranjera: $monedaExtranjera,
    paisFacturacion: $paisFacturacion,
    entidadFederativa: $entidadFederativa,
    proveedorFiscal: $proveedorFiscal,
    proveedorMercancia: $proveedorMercancia,
    calleProveedor: $calleProveedor,
    numeroInteriorProveedor: $numeroInteriorProveedor,
    numeroExteriorProveedor: $numeroExteriorProveedor,
    cpProveedor: $cpProveedor,
    municipioProveedor: $municipioProveedor,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    facturas {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fechaFacturacion
      numeroFactura
      terminoFacturacion
      monedaFacturacion
      valorDolares
      monedaExtranjera
      paisFacturacion
      entidadFederativa
      proveedorFiscal
      proveedorMercancia
      calleProveedor
      numeroInteriorProveedor
      numeroExteriorProveedor
      cpProveedor
      municipioProveedor
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_FACTURAS = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fechaFacturacion: DateTime!,
  $numeroFactura: String!,
  $terminoFacturacion: String!,
  $monedaFacturacion: String!,
  $valorDolares: Float!,
  $monedaExtranjera: Float!,
  $paisFacturacion: String!,
  $entidadFederativa: String!,
  $proveedorFiscal: String!,
  $proveedorMercancia: String!,
  $calleProveedor: String!,
  $numeroInteriorProveedor: String!,
  $numeroExteriorProveedor: String!,
  $cpProveedor: String!,
  $municipioProveedor: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveFacturas(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fechaFacturacion: $fechaFacturacion,
    numeroFactura: $numeroFactura,
    terminoFacturacion: $terminoFacturacion,
    monedaFacturacion: $monedaFacturacion,
    valorDolares: $valorDolares,
    monedaExtranjera: $monedaExtranjera,
    paisFacturacion: $paisFacturacion,
    entidadFederativa: $entidadFederativa,
    proveedorFiscal: $proveedorFiscal,
    proveedorMercancia: $proveedorMercancia,
    calleProveedor: $calleProveedor,
    numeroInteriorProveedor: $numeroInteriorProveedor,
    numeroExteriorProveedor: $numeroExteriorProveedor,
    cpProveedor: $cpProveedor,
    municipioProveedor: $municipioProveedor,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    facturas {
      id
    }
  }
}
`;

export const DELETE_FACTURAS = `
mutation Delete($id: Int!)
{
  deleteFacturas(id: $id)
  {
    id
  }
}
`;

export const FECHA_PEDIMIENTO = `
{
  fechaPedimiento {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    tipoFecha
    fechaOperacion
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_FECHA_PEDIMIENTO = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $tipoFecha: String,
  $fechaOperacion: DateTime,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setFechaPedimiento(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    tipoFecha: $tipoFecha,
    fechaOperacion: $fechaOperacion,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    fechaPedimiento {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      tipoFecha
      fechaOperacion
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_FECHA_PEDIMIENTO = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $tipoFecha: String!,
  $fechaOperacion: DateTime!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveFechaPedimiento(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    tipoFecha: $tipoFecha,
    fechaOperacion: $fechaOperacion,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    fechaPedimiento {
      id
    }
  }
}
`;

export const DELETE_FECHA_PEDIMIENTO = `
mutation Delete($id: Int!)
{
  deleteFechaPedimiento(id: $id)
  {
    id
  }
}
`;

export const GUIAS = `
{
  guias {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    numero
    tipoGuia
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_GUIAS = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $numero: String,
  $tipoGuia: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setGuias(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    numero: $numero,
    tipoGuia: $tipoGuia,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    guias {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      numero
      tipoGuia
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_GUIAS = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $numero: String!,
  $tipoGuia: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveGuias(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    numero: $numero,
    tipoGuia: $tipoGuia,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    guias {
      id
    }
  }
}
`;

export const DELETE_GUIAS = `
mutation Delete($id: Int!)
{
  deleteGuias(id: $id)
  {
    id
  }
}
`;

export const INCIDENCIAS_DEL_RECONOCIMIENTO_ADUANERO = `
{
  incidenciasDelReconocimientoAduanero {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    consecutivoRemesa
    numeroSeleccionAutomatizada
    fechaInicioReconocimiento
    horaInicioReconocimiento
    fechaFinReconocimiento
    horaFinReconocimiento
    fraccionArancelaria
    secuenciaFraccionArancelaria
    claveDocumento
    tipoOperacion
    gradoIncidencia
    fechaSeleccionAutomatizada
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_INCIDENCIAS_DEL_RECONOCIMIENTO_ADUANERO = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $consecutivoRemesa: Int,
  $numeroSeleccionAutomatizada: Int,
  $fechaInicioReconocimiento: DateTime,
  $horaInicioReconocimiento: String,
  $fechaFinReconocimiento: DateTime,
  $horaFinReconocimiento: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $claveDocumento: String,
  $tipoOperacion: String,
  $gradoIncidencia: String,
  $fechaSeleccionAutomatizada: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setIncidenciasDelReconocimientoAduanero(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    consecutivoRemesa: $consecutivoRemesa,
    numeroSeleccionAutomatizada: $numeroSeleccionAutomatizada,
    fechaInicioReconocimiento: $fechaInicioReconocimiento,
    horaInicioReconocimiento: $horaInicioReconocimiento,
    fechaFinReconocimiento: $fechaFinReconocimiento,
    horaFinReconocimiento: $horaFinReconocimiento,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveDocumento: $claveDocumento,
    tipoOperacion: $tipoOperacion,
    gradoIncidencia: $gradoIncidencia,
    fechaSeleccionAutomatizada: $fechaSeleccionAutomatizada,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    incidenciasDelReconocimientoAduanero {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      consecutivoRemesa
      numeroSeleccionAutomatizada
      fechaInicioReconocimiento
      horaInicioReconocimiento
      fechaFinReconocimiento
      horaFinReconocimiento
      fraccionArancelaria
      secuenciaFraccionArancelaria
      claveDocumento
      tipoOperacion
      gradoIncidencia
      fechaSeleccionAutomatizada
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_INCIDENCIAS_DEL_RECONOCIMIENTO_ADUANERO = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $consecutivoRemesa: Int!,
  $numeroSeleccionAutomatizada: Int!,
  $fechaInicioReconocimiento: DateTime!,
  $horaInicioReconocimiento: String!,
  $fechaFinReconocimiento: DateTime!,
  $horaFinReconocimiento: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $claveDocumento: String!,
  $tipoOperacion: String!,
  $gradoIncidencia: String!,
  $fechaSeleccionAutomatizada: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveIncidenciasDelReconocimientoAduanero(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    consecutivoRemesa: $consecutivoRemesa,
    numeroSeleccionAutomatizada: $numeroSeleccionAutomatizada,
    fechaInicioReconocimiento: $fechaInicioReconocimiento,
    horaInicioReconocimiento: $horaInicioReconocimiento,
    fechaFinReconocimiento: $fechaFinReconocimiento,
    horaFinReconocimiento: $horaFinReconocimiento,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveDocumento: $claveDocumento,
    tipoOperacion: $tipoOperacion,
    gradoIncidencia: $gradoIncidencia,
    fechaSeleccionAutomatizada: $fechaSeleccionAutomatizada,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    incidenciasDelReconocimientoAduanero {
      id
    }
  }
}
`;

export const DELETE_INCIDENCIAS_DEL_RECONOCIMIENTO_ADUANERO = `
mutation Delete($id: Int!)
{
  deleteIncidenciasDelReconocimientoAduanero(id: $id)
  {
    id
  }
}
`;

export const MERCANCIAS = `
{
  mercancias {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    vin
    kilometraje
    fechaPagoReal
    pns
    pnszs
    zip {
      id
    }
  }
}
`;

export const SET_MERCANCIAS = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $vin: String,
  $kilometraje: Int,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $zip: Int,
)
{
  setMercancias(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    vin: $vin,
    kilometraje: $kilometraje,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    mercancias {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      vin
      kilometraje
      fechaPagoReal
      pns
      pnszs
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_MERCANCIAS = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $vin: String!,
  $kilometraje: Int!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $zip: Int!,
)
{
  saveMercancias(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    vin: $vin,
    kilometraje: $kilometraje,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    mercancias {
      id
    }
  }
}
`;

export const DELETE_MERCANCIAS = `
mutation Delete($id: Int!)
{
  deleteMercancias(id: $id)
  {
    id
  }
}
`;

export const OBSERVACIONES_DE_LA_PARTIDA = `
{
  observacionesDeLaPartida {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    secuenciaObservacion
    observaciones
    fechaPagoReal
    pns
    pnszs
    zip {
      id
    }
  }
}
`;

export const SET_OBSERVACIONES_DE_LA_PARTIDA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $secuenciaObservacion: Int,
  $observaciones: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $zip: Int,
)
{
  setObservacionesDeLaPartida(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    secuenciaObservacion: $secuenciaObservacion,
    observaciones: $observaciones,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    observacionesDeLaPartida {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      secuenciaObservacion
      observaciones
      fechaPagoReal
      pns
      pnszs
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_OBSERVACIONES_DE_LA_PARTIDA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $secuenciaObservacion: Int!,
  $observaciones: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $zip: Int!,
)
{
  saveObservacionesDeLaPartida(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    secuenciaObservacion: $secuenciaObservacion,
    observaciones: $observaciones,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    observacionesDeLaPartida {
      id
    }
  }
}
`;

export const DELETE_OBSERVACIONES_DE_LA_PARTIDA = `
mutation Delete($id: Int!)
{
  deleteObservacionesDeLaPartida(id: $id)
  {
    id
  }
}
`;

export const OBSERVACIONES_PEDIMIENTO = `
{
  observacionesPedimiento {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    secuenciaObservacion
    observaciones
    tipoPedimiento
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_OBSERVACIONES_PEDIMIENTO = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $secuenciaObservacion: Int,
  $observaciones: String,
  $tipoPedimiento: Int,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setObservacionesPedimiento(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    secuenciaObservacion: $secuenciaObservacion,
    observaciones: $observaciones,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    observacionesPedimiento {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      secuenciaObservacion
      observaciones
      tipoPedimiento
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_OBSERVACIONES_PEDIMIENTO = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $secuenciaObservacion: Int!,
  $observaciones: String!,
  $tipoPedimiento: Int!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveObservacionesPedimiento(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    secuenciaObservacion: $secuenciaObservacion,
    observaciones: $observaciones,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    observacionesPedimiento {
      id
    }
  }
}
`;

export const DELETE_OBSERVACIONES_PEDIMIENTO = `
mutation Delete($id: Int!)
{
  deleteObservacionesPedimiento(id: $id)
  {
    id
  }
}
`;

export const PARTIDAS = `
{
  partidas {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    subdivisionFraccionArancelaria
    descripcionMercancia
    precioUnitario
    valorAduana
    valorComercial
    valorDolares
    cantidadMercancias
    unidadMedidaComercial
    cantidadMercanciaTarifa
    unidadMedidaTarifa
    valorAgregado
    vinculacion
    metodoDeValorizacion
    mercanciaProducto
    marcaMercancia
    modeloMercancia
    paisOrigenDestino
    paisCompradorVendedor
    entidadFederativaOrigen
    entidadFederativaDestino
    entidadFederativaComprador
    entidadFederativaVendedor
    tipoOperacion
    documento
    fechaPagoReal
    pns
    pnszs
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_PARTIDAS = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $subdivisionFraccionArancelaria: Int,
  $descripcionMercancia: String,
  $precioUnitario: Float,
  $valorAduana: Float,
  $valorComercial: Float,
  $valorDolares: Float,
  $cantidadMercancias: Float,
  $unidadMedidaComercial: String,
  $cantidadMercanciaTarifa: Float,
  $unidadMedidaTarifa: String,
  $valorAgregado: Float,
  $vinculacion: Int,
  $metodoDeValorizacion: Int,
  $mercanciaProducto: String,
  $marcaMercancia: String,
  $modeloMercancia: String,
  $paisOrigenDestino: String,
  $paisCompradorVendedor: String,
  $entidadFederativaOrigen: String,
  $entidadFederativaDestino: String,
  $entidadFederativaComprador: String,
  $entidadFederativaVendedor: String,
  $tipoOperacion: String,
  $documento: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $pnsz: String,
  $zip: Int,
)
{
  setPartidas(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    subdivisionFraccionArancelaria: $subdivisionFraccionArancelaria,
    descripcionMercancia: $descripcionMercancia,
    precioUnitario: $precioUnitario,
    valorAduana: $valorAduana,
    valorComercial: $valorComercial,
    valorDolares: $valorDolares,
    cantidadMercancias: $cantidadMercancias,
    unidadMedidaComercial: $unidadMedidaComercial,
    cantidadMercanciaTarifa: $cantidadMercanciaTarifa,
    unidadMedidaTarifa: $unidadMedidaTarifa,
    valorAgregado: $valorAgregado,
    vinculacion: $vinculacion,
    metodoDeValorizacion: $metodoDeValorizacion,
    mercanciaProducto: $mercanciaProducto,
    marcaMercancia: $marcaMercancia,
    modeloMercancia: $modeloMercancia,
    paisOrigenDestino: $paisOrigenDestino,
    paisCompradorVendedor: $paisCompradorVendedor,
    entidadFederativaOrigen: $entidadFederativaOrigen,
    entidadFederativaDestino: $entidadFederativaDestino,
    entidadFederativaComprador: $entidadFederativaComprador,
    entidadFederativaVendedor: $entidadFederativaVendedor,
    tipoOperacion: $tipoOperacion,
    documento: $documento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    partidas {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      subdivisionFraccionArancelaria
      descripcionMercancia
      precioUnitario
      valorAduana
      valorComercial
      valorDolares
      cantidadMercancias
      unidadMedidaComercial
      cantidadMercanciaTarifa
      unidadMedidaTarifa
      valorAgregado
      vinculacion
      metodoDeValorizacion
      mercanciaProducto
      marcaMercancia
      modeloMercancia
      paisOrigenDestino
      paisCompradorVendedor
      entidadFederativaOrigen
      entidadFederativaDestino
      entidadFederativaComprador
      entidadFederativaVendedor
      tipoOperacion
      documento
      fechaPagoReal
      pns
      pnszs
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_PARTIDAS = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $subdivisionFraccionArancelaria: Int!,
  $descripcionMercancia: String!,
  $precioUnitario: Float!,
  $valorAduana: Float!,
  $valorComercial: Float!,
  $valorDolares: Float!,
  $cantidadMercancias: Float!,
  $unidadMedidaComercial: String!,
  $cantidadMercanciaTarifa: Float!,
  $unidadMedidaTarifa: String!,
  $valorAgregado: Float!,
  $vinculacion: Int!,
  $metodoDeValorizacion: Int!,
  $mercanciaProducto: String!,
  $marcaMercancia: String!,
  $modeloMercancia: String!,
  $paisOrigenDestino: String!,
  $paisCompradorVendedor: String!,
  $entidadFederativaOrigen: String!,
  $entidadFederativaDestino: String!,
  $entidadFederativaComprador: String!,
  $entidadFederativaVendedor: String!,
  $tipoOperacion: String!,
  $documento: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  savePartidas(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    subdivisionFraccionArancelaria: $subdivisionFraccionArancelaria,
    descripcionMercancia: $descripcionMercancia,
    precioUnitario: $precioUnitario,
    valorAduana: $valorAduana,
    valorComercial: $valorComercial,
    valorDolares: $valorDolares,
    cantidadMercancias: $cantidadMercancias,
    unidadMedidaComercial: $unidadMedidaComercial,
    cantidadMercanciaTarifa: $cantidadMercanciaTarifa,
    unidadMedidaTarifa: $unidadMedidaTarifa,
    valorAgregado: $valorAgregado,
    vinculacion: $vinculacion,
    metodoDeValorizacion: $metodoDeValorizacion,
    mercanciaProducto: $mercanciaProducto,
    marcaMercancia: $marcaMercancia,
    modeloMercancia: $modeloMercancia,
    paisOrigenDestino: $paisOrigenDestino,
    paisCompradorVendedor: $paisCompradorVendedor,
    entidadFederativaOrigen: $entidadFederativaOrigen,
    entidadFederativaDestino: $entidadFederativaDestino,
    entidadFederativaComprador: $entidadFederativaComprador,
    entidadFederativaVendedor: $entidadFederativaVendedor,
    tipoOperacion: $tipoOperacion,
    documento: $documento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    partidas {
      id
    }
  }
}
`;

export const DELETE_PARTIDAS = `
mutation Delete($id: Int!)
{
  deletePartidas(id: $id)
  {
    id
  }
}
`;

export const PERMISOS_PARTIDA = `
{
  permisosPartida {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    clavePermiso
    firmaDescargo
    numeroPermiso
    valorComercialDolares
    mercanciaMedidaTarifa
    fechaPagoReal
    pns
    pnszs
    zip {
      id
    }
  }
}
`;

export const SET_PERMISOS_PARTIDA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $clavePermiso: String,
  $firmaDescargo: String,
  $numeroPermiso: String,
  $valorComercialDolares: Float,
  $mercanciaMedidaTarifa: Float,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $zip: Int,
)
{
  setPermisosPartida(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    clavePermiso: $clavePermiso,
    firmaDescargo: $firmaDescargo,
    numeroPermiso: $numeroPermiso,
    valorComercialDolares: $valorComercialDolares,
    mercanciaMedidaTarifa: $mercanciaMedidaTarifa,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    permisosPartida {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      clavePermiso
      firmaDescargo
      numeroPermiso
      valorComercialDolares
      mercanciaMedidaTarifa
      fechaPagoReal
      pns
      pnszs
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_PERMISOS_PARTIDA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $clavePermiso: String!,
  $firmaDescargo: String!,
  $numeroPermiso: String!,
  $valorComercialDolares: Float!,
  $mercanciaMedidaTarifa: Float!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $zip: Int!,
)
{
  savePermisosPartida(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    clavePermiso: $clavePermiso,
    firmaDescargo: $firmaDescargo,
    numeroPermiso: $numeroPermiso,
    valorComercialDolares: $valorComercialDolares,
    mercanciaMedidaTarifa: $mercanciaMedidaTarifa,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    permisosPartida {
      id
    }
  }
}
`;

export const DELETE_PERMISOS_PARTIDA = `
mutation Delete($id: Int!)
{
  deletePermisosPartida(id: $id)
  {
    id
  }
}
`;

export const PROCESS = `
{
  process {
    id
    codigo
    nombre
    status
    url
    date
    description
    team {
      id
    }
  }
}
`;

export const SET_PROCESS = `
mutation Set(
  $id: Int!,
  $codigo: String,
  $nombre: String,
  $status: String,
  $url: String,
  $date: DateTime,
  $description: String,
  $team: Int,
)
{
  setProcess(
    id: $id,
    codigo: $codigo,
    nombre: $nombre,
    status: $status,
    url: $url,
    date: $date,
    description: $description,
    team: $team,
  ) {
    process {
      id
      codigo
      nombre
      status
      url
      date
      description
      team {
        id
      }
    }
  }
}
`;

export const SAVE_PROCESS = `
mutation Save(
  $codigo: String!,
  $nombre: String!,
  $status: String!,
  $url: String!,
  $date: DateTime!,
  $description: String!,
  $team: Int!,
)
{
  saveProcess(
    codigo: $codigo,
    nombre: $nombre,
    status: $status,
    url: $url,
    date: $date,
    description: $description,
    team: $team,
  ) {
    process {
      id
    }
  }
}
`;

export const DELETE_PROCESS = `
mutation Delete($id: Int!)
{
  deleteProcess(id: $id)
  {
    id
  }
}
`;

export const RECTIFICACIONES = `
{
  rectificaciones {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    claveDocumento
    fechaPago
    pedimientoAnterior
    patenteAduanalAnterior
    seccionAduaneraDespachoAnterior
    documentoAnterior
    fechaOperacionAnterior
    numeroPedimientoOriginal
    patenteAduanalOriginal
    seccionAduaneraDespachoOriginal
    fechaPagoReal
    pns
    pnszs
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_RECTIFICACIONES = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $claveDocumento: String,
  $fechaPago: DateTime,
  $pedimientoAnterior: String,
  $patenteAduanalAnterior: String,
  $seccionAduaneraDespachoAnterior: String,
  $documentoAnterior: String,
  $fechaOperacionAnterior: DateTime,
  $numeroPedimientoOriginal: String,
  $patenteAduanalOriginal: String,
  $seccionAduaneraDespachoOriginal: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $pnsz: String,
  $zip: Int,
)
{
  setRectificaciones(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    claveDocumento: $claveDocumento,
    fechaPago: $fechaPago,
    pedimientoAnterior: $pedimientoAnterior,
    patenteAduanalAnterior: $patenteAduanalAnterior,
    seccionAduaneraDespachoAnterior: $seccionAduaneraDespachoAnterior,
    documentoAnterior: $documentoAnterior,
    fechaOperacionAnterior: $fechaOperacionAnterior,
    numeroPedimientoOriginal: $numeroPedimientoOriginal,
    patenteAduanalOriginal: $patenteAduanalOriginal,
    seccionAduaneraDespachoOriginal: $seccionAduaneraDespachoOriginal,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    rectificaciones {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      claveDocumento
      fechaPago
      pedimientoAnterior
      patenteAduanalAnterior
      seccionAduaneraDespachoAnterior
      documentoAnterior
      fechaOperacionAnterior
      numeroPedimientoOriginal
      patenteAduanalOriginal
      seccionAduaneraDespachoOriginal
      fechaPagoReal
      pns
      pnszs
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_RECTIFICACIONES = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $claveDocumento: String!,
  $fechaPago: DateTime!,
  $pedimientoAnterior: String!,
  $patenteAduanalAnterior: String!,
  $seccionAduaneraDespachoAnterior: String!,
  $documentoAnterior: String!,
  $fechaOperacionAnterior: DateTime!,
  $numeroPedimientoOriginal: String!,
  $patenteAduanalOriginal: String!,
  $seccionAduaneraDespachoOriginal: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveRectificaciones(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    claveDocumento: $claveDocumento,
    fechaPago: $fechaPago,
    pedimientoAnterior: $pedimientoAnterior,
    patenteAduanalAnterior: $patenteAduanalAnterior,
    seccionAduaneraDespachoAnterior: $seccionAduaneraDespachoAnterior,
    documentoAnterior: $documentoAnterior,
    fechaOperacionAnterior: $fechaOperacionAnterior,
    numeroPedimientoOriginal: $numeroPedimientoOriginal,
    patenteAduanalOriginal: $patenteAduanalOriginal,
    seccionAduaneraDespachoOriginal: $seccionAduaneraDespachoOriginal,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    rectificaciones {
      id
    }
  }
}
`;

export const DELETE_RECTIFICACIONES = `
mutation Delete($id: Int!)
{
  deleteRectificaciones(id: $id)
  {
    id
  }
}
`;

export const SELECCION_AUTOMATIZADA = `
{
  seleccionAutomatizada {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    cosecutivoRemesa
    seleccionAutomatizada
    fechaSeleccionAutomatizada
    horaSeleccionAutomatizada
    semaforoFiscal
    claveDocumento
    tipoOperacion
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_SELECCION_AUTOMATIZADA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $cosecutivoRemesa: Int,
  $seleccionAutomatizada: Int,
  $fechaSeleccionAutomatizada: DateTime,
  $horaSeleccionAutomatizada: String,
  $semaforoFiscal: Int,
  $claveDocumento: String,
  $tipoOperacion: String,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setSeleccionAutomatizada(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    cosecutivoRemesa: $cosecutivoRemesa,
    seleccionAutomatizada: $seleccionAutomatizada,
    fechaSeleccionAutomatizada: $fechaSeleccionAutomatizada,
    horaSeleccionAutomatizada: $horaSeleccionAutomatizada,
    semaforoFiscal: $semaforoFiscal,
    claveDocumento: $claveDocumento,
    tipoOperacion: $tipoOperacion,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    seleccionAutomatizada {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      cosecutivoRemesa
      seleccionAutomatizada
      fechaSeleccionAutomatizada
      horaSeleccionAutomatizada
      semaforoFiscal
      claveDocumento
      tipoOperacion
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_SELECCION_AUTOMATIZADA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $cosecutivoRemesa: Int!,
  $seleccionAutomatizada: Int!,
  $fechaSeleccionAutomatizada: DateTime!,
  $horaSeleccionAutomatizada: String!,
  $semaforoFiscal: Int!,
  $claveDocumento: String!,
  $tipoOperacion: String!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveSeleccionAutomatizada(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    cosecutivoRemesa: $cosecutivoRemesa,
    seleccionAutomatizada: $seleccionAutomatizada,
    fechaSeleccionAutomatizada: $fechaSeleccionAutomatizada,
    horaSeleccionAutomatizada: $horaSeleccionAutomatizada,
    semaforoFiscal: $semaforoFiscal,
    claveDocumento: $claveDocumento,
    tipoOperacion: $tipoOperacion,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    seleccionAutomatizada {
      id
    }
  }
}
`;

export const DELETE_SELECCION_AUTOMATIZADA = `
mutation Delete($id: Int!)
{
  deleteSeleccionAutomatizada(id: $id)
  {
    id
  }
}
`;

export const TABLAS = `
{
  tablas {
    id
    codigo
    nombre
  }
}
`;

export const SET_TABLAS = `
mutation Set(
  $id: Int!,
  $codigo: String,
  $nombre: String,
)
{
  setTablas(
    id: $id,
    codigo: $codigo,
    nombre: $nombre,
  ) {
    tablas {
      id
      codigo
      nombre
    }
  }
}
`;

export const SAVE_TABLAS = `
mutation Save(
  $codigo: String!,
  $nombre: String!,
)
{
  saveTablas(
    codigo: $codigo,
    nombre: $nombre,
  ) {
    tablas {
      id
    }
  }
}
`;

export const DELETE_TABLAS = `
mutation Delete($id: Int!)
{
  deleteTablas(id: $id)
  {
    id
  }
}
`;

export const TASAS_CONTRIBUCIONES_PARTIDA = `
{
  tasasContribucionesPartida {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    fraccionArancelaria
    secuenciaFraccionArancelaria
    claveContribucion
    tasaContribucion
    tipoTasa
    fechaPagoReal
    pns
    pnszs
    zip {
      id
    }
  }
}
`;

export const SET_TASAS_CONTRIBUCIONES_PARTIDA = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $fraccionArancelaria: String,
  $secuenciaFraccionArancelaria: Int,
  $claveContribucion: Int,
  $tasaContribucion: Float,
  $tipoTasa: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnszs: String,
  $zip: Int,
)
{
  setTasasContribucionesPartida(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveContribucion: $claveContribucion,
    tasaContribucion: $tasaContribucion,
    tipoTasa: $tipoTasa,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    tasasContribucionesPartida {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      fraccionArancelaria
      secuenciaFraccionArancelaria
      claveContribucion
      tasaContribucion
      tipoTasa
      fechaPagoReal
      pns
      pnszs
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_TASAS_CONTRIBUCIONES_PARTIDA = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $fraccionArancelaria: String!,
  $secuenciaFraccionArancelaria: Int!,
  $claveContribucion: Int!,
  $tasaContribucion: Float!,
  $tipoTasa: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnszs: String!,
  $zip: Int!,
)
{
  saveTasasContribucionesPartida(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    fraccionArancelaria: $fraccionArancelaria,
    secuenciaFraccionArancelaria: $secuenciaFraccionArancelaria,
    claveContribucion: $claveContribucion,
    tasaContribucion: $tasaContribucion,
    tipoTasa: $tipoTasa,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnszs: $pnszs,
    zip: $zip,
  ) {
    tasasContribucionesPartida {
      id
    }
  }
}
`;

export const DELETE_TASAS_CONTRIBUCIONES_PARTIDA = `
mutation Delete($id: Int!)
{
  deleteTasasContribucionesPartida(id: $id)
  {
    id
  }
}
`;

export const TASAS_PEDIMIENTO = `
{
  tasasPedimiento {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    contribucion
    tasaContribucion
    tipoTasa
    tipoPedimiento
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_TASAS_PEDIMIENTO = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $contribucion: Int,
  $tasaContribucion: Float,
  $tipoTasa: String,
  $tipoPedimiento: Int,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setTasasPedimiento(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    contribucion: $contribucion,
    tasaContribucion: $tasaContribucion,
    tipoTasa: $tipoTasa,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    tasasPedimiento {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      contribucion
      tasaContribucion
      tipoTasa
      tipoPedimiento
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_TASAS_PEDIMIENTO = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $contribucion: Int!,
  $tasaContribucion: Float!,
  $tipoTasa: String!,
  $tipoPedimiento: Int!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveTasasPedimiento(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    contribucion: $contribucion,
    tasaContribucion: $tasaContribucion,
    tipoTasa: $tipoTasa,
    tipoPedimiento: $tipoPedimiento,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    tasasPedimiento {
      id
    }
  }
}
`;

export const DELETE_TASAS_PEDIMIENTO = `
mutation Delete($id: Int!)
{
  deleteTasasPedimiento(id: $id)
  {
    id
  }
}
`;

export const TEAM = `
{
  team {
    id
    name
  }
}
`;

export const SET_TEAM = `
mutation Set(
  $id: Int!,
  $name: String,
)
{
  setTeam(
    id: $id,
    name: $name,
  ) {
    team {
      id
      name
    }
  }
}
`;

export const SAVE_TEAM = `
mutation Save(
  $name: String!,
)
{
  saveTeam(
    name: $name,
  ) {
    team {
      id
    }
  }
}
`;

export const DELETE_TEAM = `
mutation Delete($id: Int!)
{
  deleteTeam(id: $id)
  {
    id
  }
}
`;

export const TRANSPORTE_MERCANCIAS = `
{
  transporteMercancias {
    id
    patenteAduanal
    numeroPedimiento
    seccionAduaneraDespacho
    rfcTransportista
    curpTransportista
    nombreTransportista
    paisTransporte
    identificadorTransporte
    fechaPagoReal
    pns
    pnsz
    zip {
      id
    }
  }
}
`;

export const SET_TRANSPORTE_MERCANCIAS = `
mutation Set(
  $id: Int!,
  $patenteAduanal: String,
  $numeroPedimiento: String,
  $seccionAduaneraDespacho: String,
  $rfcTransportista: String,
  $curpTransportista: String,
  $nombreTransportista: String,
  $paisTransporte: String,
  $identificadorTransporte: String,
  $fechaPagoReal: DateTime,
  $pns: String,
  $pnsz: String,
  $zip: Int,
)
{
  setTransporteMercancias(
    id: $id,
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    rfcTransportista: $rfcTransportista,
    curpTransportista: $curpTransportista,
    nombreTransportista: $nombreTransportista,
    paisTransporte: $paisTransporte,
    identificadorTransporte: $identificadorTransporte,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    transporteMercancias {
      id
      patenteAduanal
      numeroPedimiento
      seccionAduaneraDespacho
      rfcTransportista
      curpTransportista
      nombreTransportista
      paisTransporte
      identificadorTransporte
      fechaPagoReal
      pns
      pnsz
      zip {
        id
      }
    }
  }
}
`;

export const SAVE_TRANSPORTE_MERCANCIAS = `
mutation Save(
  $patenteAduanal: String!,
  $numeroPedimiento: String!,
  $seccionAduaneraDespacho: String!,
  $rfcTransportista: String!,
  $curpTransportista: String!,
  $nombreTransportista: String!,
  $paisTransporte: String!,
  $identificadorTransporte: String!,
  $fechaPagoReal: DateTime!,
  $pns: String!,
  $pnsz: String!,
  $zip: Int!,
)
{
  saveTransporteMercancias(
    patenteAduanal: $patenteAduanal,
    numeroPedimiento: $numeroPedimiento,
    seccionAduaneraDespacho: $seccionAduaneraDespacho,
    rfcTransportista: $rfcTransportista,
    curpTransportista: $curpTransportista,
    nombreTransportista: $nombreTransportista,
    paisTransporte: $paisTransporte,
    identificadorTransporte: $identificadorTransporte,
    fechaPagoReal: $fechaPagoReal,
    pns: $pns,
    pnsz: $pnsz,
    zip: $zip,
  ) {
    transporteMercancias {
      id
    }
  }
}
`;

export const DELETE_TRANSPORTE_MERCANCIAS = `
mutation Delete($id: Int!)
{
  deleteTransporteMercancias(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
    teams {
      id
    }
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
  $teams: [Int],
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    teams: $teams,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
      teams {
        id
      }
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
  $teams: [Int],
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    teams: $teams,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;

export const ZIP = `
{
  zip {
    id
    name
    client {
      id
    }
  }
}
`;

export const SET_ZIP = `
mutation Set(
  $id: Int!,
  $name: String,
  $client: Int,
)
{
  setZip(
    id: $id,
    name: $name,
    client: $client,
  ) {
    zip {
      id
      name
      client {
        id
      }
    }
  }
}
`;

export const SAVE_ZIP = `
mutation Save(
  $name: String!,
  $client: Int!,
)
{
  saveZip(
    name: $name,
    client: $client,
  ) {
    zip {
      id
    }
  }
}
`;

export const DELETE_ZIP = `
mutation Delete($id: Int!)
{
  deleteZip(id: $id)
  {
    id
  }
}
`;