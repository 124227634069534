import React from 'react';
import { useQuery } from 'seed/gql'

import { Link, Route } from 'react-router-dom';
import PrintDialog from 'components/dashboard/PrintDialog';
import { Loading } from 'seed/helpers'
import { Modal } from 'seed/helpers'
import styles from 'styles/css/dashboard/Dashboard.module.css';

function Dashboard(props) {
  const { url, path } = props.match;
  const { panel_id = "" } = props.match.params;
  let pageId = "ReportSectionc9beedcd1ae8c37b56b6";
  const userId = sessionStorage.getItem("id")
  const isAdmin = sessionStorage.getItem("ADMIN")

  const qClients = useQuery(`{
    clients {     
      name
    }
  
  }`, !isAdmin ? `team.users = ${userId}` : "", { orderBy: "-name" });

  if (qClients.isLoading) return <Loading />
  if (qClients.error) return "Error"

  let screenHeight = window.screen.height
  let { clients = [] } = qClients.data
  let filter = clients.map(c => c.id).toString()
  if (filter == "") return <div>No hay clientes registrados</div>
  let filterUri = encodeURI(`filter=A0_cliente/id in (${filter})`);

  const PrintModal = props =>
    <Modal width={470} height={700}{...props} >
      <PrintDialog {...props} />
    </Modal>

  return <div className={styles.module} id="dashboard">
    <Link to={`${url}/print`} className={styles.printButton}>Imprimir reporte</Link>
    <iframe className={styles.frame}
      style={{ height: screenHeight - 220 }}
      title="Panel"
      
      src={`https://app.powerbi.com/reportEmbed?reportId=dfd812a3-708b-437e-a86e-b97dbc4304ab&autoAuth=true&ctid=5a3c2a26-df13-4524-9df2-07d9a4d4a7d0&language=es-MX
    &pageName=${pageId}&${filterUri}`}></iframe>
    <div className={styles.footer_1}></div>
    <div className={styles.footer_2}></div>

    <Route
      path={`${path}/print`}
      component={PrintModal} />
  </div>
}


export default Dashboard;
