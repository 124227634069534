import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from 'styles/css/navigation/Tabnav.module.css';

function Sidenav(props) {
  const { url } = props.match;
  return (
    <div className={styles.module}>
      <nav className={styles.nav}>
        <NavLink
          to={`${url}/admin`}
          className={styles.item}
          activeClassName={styles.active}>
          <i className="fas fa-database"></i>
              Adm. de datos
        </NavLink>
        <a href="https://app.powerbi.com/reportEmbed?reportId=dfd812a3-708b-437e-a86e-b97dbc4304ab&autoAuth=true&ctid=5a3c2a26-df13-4524-9df2-07d9a4d4a7d0&language=es-MX"
          className={cx(styles.item)}
          activeClassName={styles.active}>
          <i className="fas fa-chart-pie"></i>
              Visor Gráfico
        </a>
        <NavLink
          to={`${url}/clients`}
          className={cx(styles.item)}
          activeClassName={styles.active}>
          <i className="fas fa-users"></i>
              Clientes
        </NavLink>
        <NavLink
          to={`${url}/zips`}
          className={cx(styles.item)}
          activeClassName={styles.active}>
          <i className="fas fa-folder"></i>
              Datastages (zips)
        </NavLink>
      </nav>
    </div>
  );
}

export default Sidenav;
