import React, { useState } from 'react';
import Select from 'react-select';
import { usePost } from 'seed/api'
import { useQuery } from 'seed/gql'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { Loading } from 'seed/helpers'
import styles from 'styles/css/processes/Export.module.css';


function Request(props) {
  const userId = sessionStorage.getItem("id")
  const isAdmin = sessionStorage.getItem("ADMIN")

  const qClients = useQuery(`{
    clients {
      id     
      name
    }
  
  }`, !isAdmin ? `team.users = ${userId}` : "" , { orderBy: "name" });
  const [showPartidas, setShowPartidas] = useState(false);
  const [showPedimentos, setShowPedimentos] = useState(true)

  const [cSend, qSend] = usePost("/processes/export_excel", {
    onCompleted: () => window.location.href = "/",
    onError: () => alert("Ha ocurrido un error, prueba de nuevo")
  });

  if (qClients.isLoading) return <Loading />
  if (qClients.error) return "Error"

  const { clients = [] } = qClients.data

  let clientVals = clients.map(client => ({
    value: client.id,
    label: client.name
  }));

  const PEDIMENTOS = [
    { value: 501, label: "501 - Datos Generales", isFixed: true },
    { value: 502, label: "502 - Transporte de Marcancías" },
    { value: 503, label: "503 - Guías" },
    { value: 504, label: "504 - Contenedores" },
    { value: 505, label: "505 - Facturas" },
    { value: 506, label: "506 - Fecha de Pedimento" },
    { value: 507, label: "507 - Casos del Pedimento" },
    { value: 508, label: "508 - Cuentas Aduaneras de Garantía del Pedimento" },
    { value: 509, label: "509 - Tasas del Pedimento" },
    { value: 510, label: "510 - Contribuciones del Pedimento" },
    { value: 511, label: "511 - Observaciones del Pedimento" },
    { value: 512, label: "512 - Descargos de Mercancía" },
    { value: 520, label: "520 - Destinatarios de la Mercancía" },
    { value: 701, label: "701 - Rectificaciones" },
    { value: 702, label: "520 - Diferencias de Contribuciones del Pedimento" },
  ]

  const PARTIDAS = [
    { value: 551, label: "551 - Partidas", isFixed: true },
    { value: 552, label: "552 - Mercancías" },
    { value: 553, label: "553 - Permisos de Partida" },
    { value: 554, label: "554 - Casos de la Partida" },
    { value: 555, label: "555 - Cuentas Aduaneras de Garantía de la Partida" },
    { value: 556, label: "556 - Tasas de la contribución de la Partida" },
    { value: 557, label: "557 - Contribución de la Partida" },
    { value: 558, label: "558 - Observaciones de la Partida" },
  ]

  const onSubmit = (values) => {
    const ini_date = values.ini_date ? values.ini_date : new Date(2000, 1, 1);
    const end_date = values.end_date ? values.end_date : new Date(2030, 1, 1);
    let tablas = [];
    if (showPedimentos) tablas = tablas.concat(values.pedimentos);
    if (showPartidas) tablas = tablas.concat(values.partidas);
    const data = {
      tablas: tablas.join(","),
      cid: values.client,
      fechaInicial: ini_date,
      fechaFinal: end_date
    }
    cSend(data);
  }

  const parseDate = (rawDate) => {
    if (rawDate == null) return "";
    let date1 = new Date(rawDate.getTime() + rawDate.getTimezoneOffset() * 60 * 1000);
    let data = new Date(date1.toUTCString());
    let month = (data.getMonth() + 1 < 10 ? "0" : "") + (data.getMonth() + 1);
    let day = (data.getDate() < 10 ? "0" : "") + data.getDate();
    let res = data.getFullYear().toString() + '-' + month + '-' + day;
    return res;
  }

  const selectStyles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: '#ddd' } : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  const StyleSwitch = withStyles({
    switchBase: {
      color: "#777",
      '&$checked': {
        color: "#007473",
      },
      '&$checked + $track': {
        backgroundColor: "#007473",
      },
    },
    checked: {},
    track: {},
  })(Switch);


  return (
    <div className={styles.module}>
      <div className={styles.jumbotron}>
        <h4 style={{ color: "#fff" }}>Generación de Excel</h4>
      </div>

      <div className={(styles.paper, styles.form)}>

        <Formik
          onSubmit={onSubmit}
          initialValues={{
            clients: clientVals.map(p => p.value),
            pedimentos: [PEDIMENTOS.map(p => p.value)[0]],
            partidas: [PARTIDAS.map(p => p.value)[0]],
            ini_date: null,
            end_date: null
          }}
          render={props => (
            <form onSubmit={props.handleSubmit}>

              <div className={styles.subtitle}>Cliente</div>
              <select
                onChange={e => props.setFieldValue("client", e.target.value)}
                required>
                <option value="">Selecciona un cliente</option>
                {
                  clientVals.map(client =>
                    <option value={client.value}>{isAdmin ? (client.value + " - ") : ""}{client.label}</option>)
                }
              </select>
              <div className={styles.subtitle}>Rango de fechas</div>
              <input type="date"
                name="ini_date"
                value={parseDate(props.values.ini_date)}
                onChange={e => props.setFieldValue("ini_date", new Date(e.target.value))}></input>
              &nbsp;&nbsp;
              <input type="date"
                name="end_date"
                value={parseDate(props.values.end_date)}
                onChange={e => props.setFieldValue("end_date", new Date(e.target.value))}></input>
              <br />
              <br />
              <div className={styles.subtitle}>Tablas - Nivel Pedimento
                <StyleSwitch
                  size="small"
                  checked={showPedimentos}
                  onChange={() => setShowPedimentos(!showPedimentos)} />
              </div>
              {
                showPedimentos ?
                  <Select
                    className={styles.multiSelect}
                    options={PEDIMENTOS}
                    isMulti={true}
                    styles={selectStyles}
                    defaultValue={[PEDIMENTOS[0]]}
                    isClearable={false}
                    onChange={val => props.setFieldValue("pedimentos", val ? val.map(v => v.value) : [])} />
                  : null
              }
              <div className={styles.subtitle}>Tablas - Nivel Partida
                <StyleSwitch
                  size="small"
                  checked={showPartidas}
                  onChange={() => setShowPartidas(!showPartidas)} />
              </div>
              {
                showPartidas ?
                  <Select
                    className={styles.multiSelect}
                    options={PARTIDAS}
                    isMulti={true}
                    styles={selectStyles}
                    defaultValue={[PARTIDAS[0]]}
                    isClearable={false}
                    onChange={val => props.setFieldValue("partidas", val ? val.map(v => v.value) : [])} />
                  : null
              }

              <button className={styles.call}>Exportar</button>
            </form>
          )} />
      </div>
      {
        qSend.isLoading ? <Loading /> : null
      }
    </div>
  );
}

export default Request;
