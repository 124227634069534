import React, { useState } from 'react';
import cx from 'classnames';
import { usePost, useGetCall } from "seed/api";
import { API_URL } from 'settings';
import styles from 'styles/css/auth/Login.module.css';
import PropTypes from "prop-types";

function Login(props) {
  const [state, setState] = useState(0);
  const params = new URLSearchParams(props.location.search);

  const [callLogin, reqLogin] = usePost("/auth/login", {
    onCompleted: (data) => {
      sessionStorage.setItem("token", data.key);
      sessionStorage.setItem("id", data.user);
      props.history.replace(params.get("next") ?? "/");
    },
    onError: error => setState({ error: 'Usuario o contraseña invalido' }),
    includeAuth: false
  });

  const redirect_url = API_URL + "/users/azure_auth/"
  const redirect_href = "https://login.microsoftonline.com/5a3c2a26-df13-4524-9df2-07d9a4d4a7d0/oauth2/v2.0/authorize?client_id=a2852a12-bbfc-48e0-b694-999138377929&response_type=code&response_mode=query&scope=openid%20User.Read&prompt=select_account&state=" + encodeURI("crz-link-" + redirect_url);


  const onSubmit = e => {
    e.preventDefault();
    let email = e.target.email.value;
    let password = e.target.password.value;
    callLogin({ email: email, password: password });
  }

  return (
    <div className={styles.module}>
      <div className={styles.background}
        style={{ backgroundImage: `url(/resources/images/wave.svg)` }}>

        <img
          className={styles.logo}
          src="/resources/images/logo.png"
          alt="Logo" />

        <div className={cx(styles.container, 'animated zoomIn')}>

          <label className={styles.title}>Inicio de sesión<br /><span>Visor de Comercio Exterior</span></label>
          <form onSubmit={onSubmit}>
            <label className={styles.emailLbl}>Email</label><br />
            <input className={styles.email}
              name="email"
              type="email"
              placeholder="correo@empresa.com"
              required /><br />
            <label className={styles.passwordLbl}>Contraseña</label><br />
            <input className={styles.password}
              name="password"
              type="password"
              placeholder="Contraseña"
              required /><br />
            {state.error ?
              <div className={styles.error}>{state.error}</div> : null}
            <button type="submit" className={styles.submit}>Iniciar sesión</button><br />
            <hr /><div className={styles.or}>o</div>
            <a href={redirect_href} className={styles.submitCrz}>Iniciar sesión con cuenta CRZ</a>
          </form>

        </div>

      </div>
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Login;