import React from 'react';
import queryString from 'query-string';

function LoginAdminAuth(props) {
  const { status, id, token } = queryString.parse(props.location.search)
  if (status == "OK") {
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('token', token);
    window.location.replace("/");
  } else return <div>Ha ocurrido un error iniciando sesión</div>;
}

export default LoginAdminAuth;
