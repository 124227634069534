import React from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api'
import { useQuery, useDetail, useDelete } from 'seed/gql'
import { DELETE_CLIENT } from 'seed/gql/queries'
import { Loading } from 'seed/helpers'
import styles from 'styles/css/admin/Clients.module.css';


function Clients(props) {
  const userId = sessionStorage.getItem("id")
  const isAdmin = sessionStorage.getItem("ADMIN")

  const qClients = useQuery(`{
    clients {     
      name
    }  
  }`, !isAdmin ? `team.users = ${userId}` : "", { orderBy: "name" });
  const qUser = useDetail(`{ user { teams {} } }`, userId);

  const [cSave, qSave] = usePost("/clients", {
    onCompleted: () => window.location.reload()
  });

  const [cDelete, qDelete] = useDelete(DELETE_CLIENT, {
    onCompleted: () => window.location.reload()
  });

  if (qClients.isLoading || qDelete.isLoading || qSave.isLoading || qDelete.isLoading) return <Loading />
  if (qClients.error || qDelete.error || qSave.error || qDelete.error) return "Error"

  const { clients = [] } = qClients.data
  const { user = {} } = qUser.data

  const deleteClient = (clientId) => {
    const p = window.prompt("Al eliminar el cliente, se eliminaran todos los datos, para continuar teclea ELIMINAR");
    if (p == "ELIMINAR")
      cDelete({ id: clientId });
  }

  const onRegistryClient = () => {
    const name = prompt("Nombre del cliente");
    if (name)
      cSave({
        name: name,
        team_id: user.teams[0].id
      })
  }

  return (
    <div className={styles.module}>
      <div className={styles.jumbotron}>
        <h4 style={{ color: "#fff" }}>Lista de clientes</h4>
      </div>

      <div className={cx(styles.paper, styles.form)}>

        <div className={styles.registryClient}
          onClick={onRegistryClient}>Registrar cliente</div>

        <table>
          <tr>
            <th>Nombre</th>
            <th></th>
          </tr>
          {
            clients.map(client =>
              <tr>
                <td>{isAdmin ? (client.id + " - ") : ""}{client.name}</td>
                <td>
                  <i className="fas fa-trash" style={{ minWidth: "20px", minHeight: "20px", display: "block" }} onClick={() => deleteClient(client.id)}></i>
                </td>
              </tr>)
          }
        </table>
      </div>
    </div>
  );
}

export default Clients;
