import React from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api'
import { useQuery, useDetail } from 'seed/gql'
import { API_URL } from 'settings';
import { Loading } from 'seed/helpers'
import styles from 'styles/css/processes/Upload.module.css';


function UploadForm(props) {
  const userId = sessionStorage.getItem("id")
  const isAdmin = sessionStorage.getItem("ADMIN")

  const qClients = useQuery(`{
    clients {     
      name
    }  
  }`, !isAdmin ?  `team.users = ${userId}` : "", { orderBy: "name" });
  const qUser = useDetail(`{ user { teams {} } }`, userId);

  const [cSave, qSave] = usePost("/clients", {
    onCompleted: () => window.location.reload()
  });

  if (qClients.isLoading || qUser.isLoading || qSave.isLoading) return <Loading />
  if (qClients.error || qUser.error || qSave.error) return "Error"


  const { clients = [] } = qClients.data
  const { user = {} } = qUser.data
  let clientVals = clients.map(client => ({
    value: client.id,
    label: client.name
  }));

  const onRegistryClient = () => {
    const name = prompt("Nombre del cliente");
    if (name)
      cSave({
        name: name,
        team_id: user.teams[0].id
      })
  }


  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4 style={{ color: "#fff" }}>Carga de datos</h4>
      </div>

      <div className={cx(styles.paper, styles.form)}>

        <form action={`${API_URL}/zips/upload/`} method="POST" enctype="multipart/form-data">
          <span><i>Cliente</i></span>
          <select name="cid" required>
            <option value="">Selecciona un cliente</option>
            {
              clientVals.map((client, idx) =>
                <option value={client.value}>{isAdmin ? (client.value + " - ") : ""}{client.label}</option>)
            }
          </select>
          <div className={styles.registryClient}
            onClick={onRegistryClient}>Registrar cliente</div>
          <span><i>Datastages (zip con .asc)</i></span>
          <input
            type="file"
            name="archivo[]"
            className={cx(styles.fil, styles.fileFil)}
            accept={"application/zip"}
            multiple="multiple"
            required />
          <input type="hidden" name="token" value={sessionStorage.getItem("token")} />

          <button type="submit" className={cx(styles.btn, styles.btnGreen, styles.fullBtn)}>Cargar Datastage</button>

        </form>

      </div>
    </div>
  );
}

export default UploadForm;
